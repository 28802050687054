<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->

          <div class="row">
            <div class="col-6">
              <base-input
                :label="form.id ? 'Nome' : 'Nome *'"
                type="text"
                v-model="form.name"
              ></base-input>
            </div>

            <div class="col-6">
              <label for="">{{ form.id ? 'Função' : 'Função *' }}</label>
              <select :disabled="true" v-model="form.role" class="form-control">
                <option value="admin">Administrador</option>
                <option value="prefeitura">Prefeitura</option>
              </select>
            </div>

            <div class="col-6">
              <base-input
                :label="form.id ? 'Email' : 'Email *'"
                type="email"
                v-model="form.email"
              ></base-input>
            </div>

            <div class="col-6">
              <base-input
                :label="form.id ? 'Senha' : 'Senha *'"
                type="password"
                v-model="form.password"
              ></base-input>
            </div>
            <div class="col-6">
              <label>Estado</label>
              <el-select class="select-primary" v-model="selectedState" filterable placeholder="Selecione um estado">
                <el-option class="select-primary" v-for="item in states.data" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="col-6">
              <label>Cidade</label>
              <el-select :class="{'select-primary': !disabledCity}" v-model="form.selectedCity" filterable placeholder="Selecione uma cidade" :disabled="disabledCity">
                <el-option class="select-primary" v-for="item in cities.data" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
            <!--
            <div class="col-12 mt-4">
              <label>Foto de perfil </label>
              <div class="upload-container">
                <div
                  class="upload-preview"
                  :style="'background: url(' + form.avatar + ')'"
                  v-if="form.avatar !== '' && form.avatar !== null"
                >
                  <div class="remove-file" @click="form.avatar = ''">
                    <p><i class="fa fa-times"></i></p>
                    <p>Remover</p>
                  </div>
                </div>
                <div class="add-file">
                  <label
                    for="image"
                    v-if="!(form.avatar !== '' && form.avatar !== null)"
                  >
                    Selecionar <span class="tim-icons icon-upload"></span>
                  </label>
                  <label
                    for="image"
                    v-if="form.avatar !== '' && form.avatar !== null"
                  >
                    Alterar <span class="tim-icons icon-pencil"></span>
                  </label>
                  <input
                    @change.prevent="addImage"
                    type="file"
                    id="image"
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </div>
              </div>
            </div>

          -->
          </div>

          <!-- botoes -->
          <div class="row">
            <div class="col-12">
              <base-button
                class="mt-3"
                @click.prevent.stop="goBack()"
                type="danger"
                >Cancelar</base-button
              >
              <base-button class="mt-3" native-type="submit" type="success"
                >Salvar</base-button
              >
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  data: () => ({
    form: {
      avatar: '',
      role: 'prefeitura'
    },
    states: [],
    cities: [],
    selectedState: '',
    selectedCity: '',
    disabledCity: true,
    enabledCities: [],
    ready: false
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`users/${id}`)).data.user
      }
    },
    async getCitiesByState (state) {
      this.selectedCity = ''
      this.cities = await axios.get('/cities?province_id=' + state)
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`users/${id}`, this.form)).status
          }
          if (!id) status = (await this.$http.post('users', this.form)).status
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          await swal.fire({
            title: 'Salvo!',
            text: 'Usuário salvo com sucesso.',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
          this.goBack()
        } else {
          await swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Ok',
            showConfirmButton: true
          })
        }
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          type: 'error',
          confirmButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          showConfirmButton: true
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },

    money () {
      console.log(this.$helpers.formatMonetary(this.form.price))
    },

    addImage (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      const vue = this
      const file = files[0]
      var reader = new FileReader()

      reader.onload = e => {
        vue.form.avatar = e.target.result
      }
      reader.readAsDataURL(file)
    },

    validate () {
      const form = this.form
      if (!form.name || form.name === '') return false
      if (!form.role || form.role === '') return false
      if (!form.email || form.email === '') return false
      if (!form.selectedCity || form.selectedCity === '') return false
      if (!form.id) {
        if (!form.password || form.password === '') return false
      }
      return true
    }
  },
  async mounted () {
    this.getData()
    this.states = await axios.get('/provinces')
  },
  watch: {
    selectedState () {
      this.form.selectedState = this.selectedState
      this.disabledCity = false
      this.getCitiesByState(this.selectedState)
    },
    states () {
      this.ready = true
    }
  }
}
</script>
<style>
label {
  width: 100%;
}
</style>
